<template>
  <footer class="relative mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <nav
        aria-label="Footer"
        class="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6"
      >
        <button-secondary
          v-for="item in navFooterItems"
          :key="item.name"
          :label="item.name"
          :link="item.href"
        />
      </nav>

      <div class="mt-16 flex justify-center gap-x-10">
        <nuxt-link
          v-for="item in navSocialItems"
          :key="item.name"
          :to="item.href"
          target="_blank"
          class="text-gray-900 hover:text-red-600"
        >
          <span class="sr-only">
            {{ item.name }}
          </span>

          <icon
            :name="item.icon"
            class="size-6"
            aria-hidden="true"
          />
        </nuxt-link>
      </div>

      <p class="mt-10 text-center text-sm/6 text-gray-600">
        {{ appName }} - {{ appTaglineShort }}
      </p>
    </div>
  </footer>
</template>
