<template>
  <header class="absolute inset-x-0 top-0 z-50">
    <navigation-desktop @open="onMobileMenuClicked" />

    <navigation-mobile
      :opened="isMobileMenuOpened"
      @close="onMobileMenuClicked"
    />
  </header>
</template>

<script setup lang="ts">
const isMobileMenuOpened = ref<boolean>(false)

function onMobileMenuClicked (isMenuOpened: boolean) {
  isMobileMenuOpened.value = isMenuOpened
}
</script>
