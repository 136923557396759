<template>
  <div class="bg-white">
    <section-header />

    <main class="isolate">
      <slot />
    </main>

    <section-footer class="mt-32" />
  </div>
</template>
