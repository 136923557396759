<template>
  <nav
    aria-label="Global"
    class="flex items-center justify-between p-6 lg:px-8"
  >
    <div class="flex lg:flex-1">
      <nuxt-link
        to="/"
        class="-m-1.5 p-1.5"
      >
        <span class="sr-only">
          {{ appName }}
        </span>

        <img
          :alt="appLogoAlt"
          :src="appLogoUrl"
          class="h-8 w-auto"
        />
      </nuxt-link>
    </div>

    <div class="flex lg:hidden">
      <button
        type="button"
        class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900 hover:text-red-600 hover:bg-red-50/70"
        @click.prevent="emit('open', true)"
      >
        <span class="sr-only">
          Ouvrir le menu
        </span>

        <icon
          name="ph:list"
          aria-hidden="true"
          class="size-6"
        />
      </button>
    </div>

    <div class="hidden lg:flex lg:gap-x-12">
      <button-secondary
        v-for="item in navHeaderItems"
        :key="item.name"
        :label="item.name"
        :link="item.href"
        class="font-semibold"
      />
    </div>

    <div class="hidden lg:flex lg:flex-1 lg:justify-end">
      <button-secondary
        :link="appUrl"
        target="_blank"
        label="Se connecter"
        icon-name="ph:arrow-right"
        class="font-semibold"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { NavDesktopEmits } from 'types/navigation'

const emit = defineEmits<NavDesktopEmits>()

const config = useRuntimeConfig()
const env = config.public
const appUrl = env.APP_URL
</script>
