export const navHeaderItems = [
  {
    href: '/',
    name: 'Accueil'
  },
  {
    href: '/contact',
    name: 'Contact'
  }
]

export const navFooterItems = [
  {
    href: '/conditions-generales-utilisation',
    name: 'CGU'
  },
  {
    href: '/conditions-generales-vente',
    name: 'CGV'
  },
  {
    href: '/politique-confidentialite',
    name: 'Politique de confidentialité'
  }
]

export const navSocialItems = [
  {
    href: 'https://www.facebook.com/livedisplay.pro',
    name: 'Facebook',
    icon: 'ph:facebook-logo-duotone'
  },
  {
    href: 'https://www.instagram.com/livedisplay_pro/',
    name: 'Instagram',
    icon: 'ph:instagram-logo-duotone'
  },
  {
    href: 'https://twitter.com/livedisplay_pro',
    name: 'X (Twitter)',
    icon: 'ph:x-logo-duotone'
  }
]
