<template>
  <Dialog
    class="lg:hidden"
    :open="isMobileMenuOpened"
    @close="emit('close', false)"
  >
    <div class="fixed inset-0 z-50" />

    <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
      <div class="flex items-center justify-between">
        <nuxt-link
          to="/"
          class="-m-1.5 p-1.5"
          @click="emit('close', false)"
        >
          <span class="sr-only">
            {{ appName }}
          </span>

          <img
            :alt="appLogoAlt"
            :src="appLogoUrl"
            class="h-8 w-auto"
          />
        </nuxt-link>

        <button
          type="button"
          class="-m-2.5 rounded-md p-2.5 text-gray-900 hover:text-red-600 hover:bg-red-50/70"
          @click.prevent="emit('close', false)"
        >
          <span class="sr-only">
            Fermer le menu
          </span>

          <icon
            name="ph:x"
            aria-hidden="true"
            class="size-6"
          />
        </button>
      </div>

      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="space-y-2 py-6">
            <button-nav-mobile
              v-for="item in navHeaderItems"
              :key="item.name"
              :label="item.name"
              :link="item.href"
              @click="emit('close', false)"
            />
          </div>

          <div class="py-6">
            <button-nav-mobile
              label="Se connecter"
              :link="appUrl"
              target="_blank"
              @click="emit('close', false)"
            />
          </div>
        </div>
      </div>
    </DialogPanel>
  </Dialog>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel } from '@headlessui/vue'

import type { NavMobileProps, NavMobileEmits } from 'types/navigation'

const { opened: isMobileMenuOpened } = defineProps<NavMobileProps>()
const emit = defineEmits<NavMobileEmits>()

const config = useRuntimeConfig()
const env = config.public
const appUrl = env.APP_URL
</script>
